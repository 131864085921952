@media only screen and (min-width: 600px) {
    .reg-success-container-1 {
        width: 35% !important;
    }
    
    .reg-success-container-2 {
        width: 65% !important;
    }
  }

  @media only screen and (max-width: 600px) {
    .reg-success-container-1 {
        display: none !important;
    }

    .success-page-tittle {
        font-size: 150% !important;
    }
    
    .reg-success-container-2 {
        width: 100% !important;
    }
  }


.hero-template {
    align-items: center;
    background: linear-gradient(0deg,#49878a 20.62%,rgba(73, 135, 138,  0.4) 90.66%), url(../../assets/hero.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
}

.hero-text { 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    position: absolute;
    width: 100%;
    padding-right: 2%;
    left: 2%;
    bottom: 5%;
}

.hero-row{
    width: 98%;
    color: #F2F8F8;
}

.hero-tittle { 
    width: 35%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 200%;
    line-height: 39px;
}

.hero-description {
    width: 34%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    letter-spacing: -0.02em;

}

.hero-learn-more {
    width: 81px;
    height: 22px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 160%;
    letter-spacing: -0.02em;
    color: #FCF0DA;
}

.success-page-tittle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 200%;
    line-height: 39px;
    letter-spacing: -0.02em;
    color: #334155;
}

.success-page-subtittle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.02em;
    color: #475569;

}