body{
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    background-color: #fff;
}

.container{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ececec;
}

.container-top-bar-menu {
    /* width: 100%; */
    /* height: 100vh; */
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #334155;
    color:#fff;
}


label{
    font-size: 15px;
}

.logo-md{
    height: 70px;
    width: 75px;
    margin-top: 10px;
}

.container-footer{
    width: 110%;
    height: 95px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f3f3f3;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

p{
    font-size: 13px;
    color: rgb(0, 0, 0);
    margin-top: 0px;
}

a{
    text-decoration: none;
    font-size: 13px;
    font-weight: 600;
    cursor: pointer;
}

.error-message{
    color:rgb(241, 6, 6);
    font-weight: 600;
}

input[type="text"], input[type="password"],input[type="number"],input[type="date"]{
    width: 100%;
    height: 50px;
    border-radius: 10px;
    border: none;
    color:rgb(88, 88, 88);
    margin-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: rgb(243, 243, 243);
    font-size: 15px;
    font-family: 'Montserrat';
    /* -webkit-min-logical-width: calc(100% - 16px); */

};

textarea{
    width: 100%;
    height: 50px;
    border-radius: 10px;
    border: none;
    color:rgb(88, 88, 88);
    margin-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    background-color: rgb(243, 243, 243);
    font-size: 15px;
    font-family: 'Montserrat';
};

textarea:focus{
    outline: none;
}

select{
    width: 100%;
    height: 50px;
    border-radius: 10px;
    border: none;
    padding: 0 10px;
    color:rgb(88, 88, 88);
    margin-top: 5px;
    background-color: rgb(243, 243, 243);
    font-size: 15px;
    font-family: 'Montserrat';
}

select:focus{
    outline: none;
}

input:focus{
    outline: none;
}

.inner-container{
    width: 400px;
    height: auto;
    background-color: #fff;
    border-radius: 25px;
    box-shadow: 0 0 10px rgba(216, 216, 216, 0.062);
    padding: 0px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}

.inner-container-register{
    width: 400px;
    height: 450px;
    background: #47878a;
    /* background: linear-gradient(90deg, rgba(234,147,66,1) 0%, rgba(239,185,81,1) 35%, rgba(71,135,138,1) 100%); */
    border-radius: 25px;
    box-shadow: 0 0 10px rgba(216, 216, 216, 0.062);
    padding: 0px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.inner-container-register-otp{
    width: 400px;
    height: 280px;
    background: #47878a;
    /* background: linear-gradient(90deg, rgba(234,147,66,1) 0%, rgba(239,185,81,1) 35%, rgba(71,135,138,1) 100%); */
    border-radius: 25px;
    box-shadow: 0 0 10px rgba(216, 216, 216, 0.062);
    padding: 0px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 100px;

}

h4{
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    margin-top: 0px;
    color: rgb(105, 105, 105);
}

.sam{
    background-color: #b9b9b9;
}

.btn{
    width: 100%;
    height: 50px;
    border-radius: 10px;
    border: none;
    background-color: #47878a;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
}

.btn:hover{
    background-color: #225658;
}

.overlay-inner-container{
    width: 400px;
    height: auto;
    background-color: rgb(255, 255, 255);
    border-radius: 25px;
    margin-right:-50px;
    box-shadow: 0 0 10px rgba(216, 216, 216, 0.342);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    margin-top: 25px;
}

.overlay-inner-container-otp{
    width: 400px;
    height: 400px;
    background-color: rgb(255, 255, 255);
    border-radius: 25px;
    margin-right:-50px;
    box-shadow: 0 0 10px rgba(216, 216, 216, 0.342);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
    margin-top: 25px;
}

.underlay-inner-container{
    display: flex;
    flex-direction: column;
    align-items: left;
    
}

.input-container-register-form {
    margin-bottom: -8%;
}