.account-verification{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.left-verification{
    width: 40%;
    height: 100%;
    background: linear-gradient(360deg, #ffffff 10%, rgba(255, 255, 255, 0) 56.66%), url('../../assets/img5.jpg');
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}


.right-verification {
    width: 60%;
    min-width: 0;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flip-horizontal {
    transform: scaleX(-1);
}


.text-verification{
    padding: 5%;
    width: 100%;
}

.text-verification p{
    font-size: 15px;
}

.ribbon-container{
    background-image: url("../../assets/ribbon.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 5%;
}

.right-verification h3{
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 600;
}

.text-verification h1{
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 20px;
}

.right-verification h3{
    margin-bottom:0px;
}

.right-verification p{
    margin:10px;
    margin-bottom:30px;
}

@media only screen and (max-width: 768px) {
    .left-verification {
        width: 100%;
        height: 40vh;
        background-position: center;
        background-size: cover;
        justify-content: center;
    }
    .right-verification {
        width: 100%;
        justify-content: flex-start;
    }
    .text-verification {
        padding: 10%;
    }
    .right-verification h3 {
        font-size: 18px;
        margin-bottom: 10px;
    }
    .text-verification h1 {
        font-size: 20px;
        margin-bottom: 10px;
    }
    .right-verification p {
        margin: 5px;
        margin-bottom: 20px;
    }
}

.loader {
    width: 48px;
    height: 48px;
    border: 2px solid #FFF;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  .loader::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 0;
    background: #FF3D00;
    width: 3px;
    height: 24px;
    transform: translateX(-50%);
  }
      
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 