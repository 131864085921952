.selector-container{
    width: 80%;
    height: 80%;
    margin-top: 50px;
    /* padding-top: 100px; */
}



.app-selection-mobile{
    display: none;
}

.header-home-logo img{
    width: 40px;
    height: 100%;
    margin-top: -8px;
}

.header-menus{
    display: flex;
    flex-direction: row;
    width:900px;
    justify-content: space-between;
    margin-top: 20px;
}

.single-selection{
   cursor: pointer;
}

.settings-component{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 50px;
}

.settings-component-inner-right{
    width: 30%;
}

.settings-component-inner-left{
    width: 70%;
}



.flex-lg{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

  .flex-items{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

.file-uploader{
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dashed #e0e0e0;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 20px;
}

.account-type-select{
    width: 550px;
    height: 40px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.inner-acccount-type{
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.account-types{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.account-type-selected{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #47878a;
    margin-right: 20px;
}

.account-type-not-selected{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #e0e0e0;
    margin-right: 20px;
}

.edit-button{
    position: absolute;
    right: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.empty-profile-group{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 200px;
}

.select-box{
    width: 100%;
    height: 40px;
}

.profile-group{
    border-radius: 10px;
    padding: 10px;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid #e0e0e0;
}

.profile-group:hover{
    border: 1px solid #47878a;
    cursor: pointer;
    background-color: #47878a;
    color: #fff;
};

.p-h:hover{
    color: #fff;
};

.add-new-member-container{
    padding-left: 20px;
    padding-right: 20px;
};

.profile-groups-members-container{
    padding: 20px;
};

.notifications-container{
    border: 1px solid #eee;
    border-radius: 10px;
    width: 60%;
    height: auto;  
    background-color: orange; 
}

.notification{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
}
.input-container{
    width: 100%;
    margin:10px;
    margin-bottom: 0px;
}
.mobile-menu{
    display: none;
}

.input-container input{
    width: 95%;
    height: 50px;
    border-radius: 10px;
    border: none;
    padding: 0 10px;
    color:rgb(88, 88, 88);
    margin-top: 5px;
    background-color: rgb(243, 243, 243);
    font-size: 15px;
    font-family: 'Montserrat';
}

.input-container textarea{
    width: 100%;
    height: 150px;
    border-radius: 10px;
    border: none;
    padding: 0 10px;
    color:rgb(88, 88, 88);
    margin-top: 5px;
    background-color: rgb(243, 243, 243);
    font-size: 15px;
    font-family: 'Montserrat';
}

.input-container textarea:focus{
    outline: none;
}

.footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 350px;
    color:rgb(141, 141, 141);
    font-weight: 400;
}

.app-selection-desktop{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -18px;
}

.hvr-link:hover{
    color:#48878A
}

.hvr-link{
    color:#334155;
    font-weight:600;
    font-size: smaller;
    cursor: pointer;
}
.hvr-link span{
    color:#64748B;
    font-weight:400;
}

.hvr-link span:hover{
    color:#48878A
}


/* ltn__drop-menu */
.ltn__drop-menu {
  display: inline-block;
  text-align: left;
  align-items: center;
  display: flex;
}

.ltn__drop-menu li {
  margin-top: 0;
}

.ltn__drop-menu + .ltn__drop-menu {
  margin-left: 10px;
}

.ltn__drop-menu ul {
  margin-bottom: 0;
}

.ltn__drop-menu > ul > li {
  display: inline-block;
  position: relative;
}

.ltn__drop-menu > ul > li > a {
  display: block;
  text-decoration: none;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
}

.ltn__drop-menu ul li ul {
  position: absolute;
  min-width: 150px;
  right: 0;
  background-color: #fff;
  z-index: 999;
  -webkit-box-shadow: var(--ltn__box-shadow-4);
  box-shadow: var(--ltn__box-shadow-4);
  border-top: 1px solid #ececec;
  top: 130%;
  padding: 10px 0;
  margin: 0;
  -webkit-transition: opacity 0.2s ease 0.1s, visibility 0.2s ease 0.1s,
    top 0.2s ease 0.1s;
  -o-transition: opacity 0.2s ease 0.1s, visibility 0.2s ease 0.1s,
    top 0.2s ease 0.1s;
  transition: opacity 0.2s ease 0.1s, visibility 0.2s ease 0.1s,
    top 0.2s ease 0.1s;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
  visibility: hidden;
  height: 258px;
  width: 400px;
  border:1px solid #eee;
  margin-top: 5px;
  /* margin-right:-280px; */
}



.ltn__drop-menu ul li ul li {
  display: block;
  padding: 5px 15px;
  font-size: 16px;
  color: var(--ltn__paragraph-color);
  font-weight: 500;
}

.ltn__drop-menu ul li ul li a {
  color: inherit;
}

.ltn__drop-menu ul li:hover > ul {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

.ltn__drop-menu ul li:hover > a {
  color: var(--ltn__secondary-color);
}


.dropzone, .dropzone * {
  box-sizing: border-box;
}

.dropzone.dz-clickable {
  cursor: pointer;
}
.dropzone.dz-clickable .dz-message, .dropzone.dz-clickable .dz-message * {
  cursor: pointer;
}
.dropzone.dz-started .dz-message {
  display: none;
}
.dropzone.dz-drag-hover {
  border-style: solid;
}
.dropzone.dz-drag-hover .dz-message {
  opacity: 0.5;
}
.dropzone .dz-message {
  text-align: center;
  margin: 2em 0;
}
.dropzone .dz-message .dz-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.dropzone .dz-preview {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 16px;
  min-height: 100px;
}
.dropzone .dz-preview:hover {
  z-index: 1000;
}
.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}
.dropzone .dz-preview.dz-file-preview .dz-image {
  border-radius: 20px;
  background: #999;
  background: linear-gradient(to bottom, #eee, #ddd);
}
.dropzone .dz-preview.dz-file-preview .dz-details {
  opacity: 1;
}
.dropzone .dz-preview.dz-image-preview .dz-details {
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}
.dropzone .dz-preview .dz-remove {
  font-size: 14px;
  text-align: center;
  display: block;
  cursor: pointer;
  border: none;
  position: relative;
}
.dropzone .dz-preview .dz-remove:hover {
  text-decoration: underline;
}
.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}
.dropzone .dz-preview .dz-details {
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  font-size: 13px;
  min-width: 100%;
  max-width: 100%;
  padding: 2em 1em;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  line-height: 150%;
}
.dropzone .dz-preview .dz-details .dz-size {
  margin-bottom: 1em;
  font-size: 16px;
}
.dropzone .dz-preview .dz-details .dz-filename {
  white-space: nowrap;
}
.dropzone .dz-preview .dz-details .dz-filename:hover span {
  border: 1px solid rgba(200, 200, 200, 0.8);
  background-color: rgba(255, 255, 255, 0.8);
}
.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
  overflow: hidden;
  text-overflow: ellipsis;
}
.dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
  border: 1px solid transparent;
}
.dropzone .dz-preview .dz-details .dz-filename span, .dropzone .dz-preview .dz-details .dz-size span {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 0 0.4em;
  border-radius: 3px;
}
.dropzone .dz-preview:hover .dz-image img {
  -webkit-transform: scale(1.05, 1.05);
  -moz-transform: scale(1.05, 1.05);
  -ms-transform: scale(1.05, 1.05);
  -o-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  -webkit-filter: blur(8px);
  filter: blur(8px);
}
.dropzone .dz-preview .dz-image {
  border-radius: 20px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
  /* background-color: $gray-100 ; */
}

.dropzone .dz-preview .dz-image img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.dropzone .dz-preview.dz-success .dz-success-mark {
  -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
}
.dropzone .dz-preview.dz-error .dz-error-mark {
  opacity: 1;
  -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
}
.dropzone .dz-preview .dz-success-mark, .dropzone .dz-preview .dz-error-mark {
  pointer-events: none;
  opacity: 0;
  z-index: 500;
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  margin-left: -27px;
  margin-top: -27px;
}
.dropzone .dz-preview .dz-success-mark svg, .dropzone .dz-preview .dz-error-mark svg {
  display: block;
  width: 54px;
  height: 54px;
}
.dropzone .dz-preview.dz-processing .dz-progress {
  opacity: 1;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.dropzone .dz-preview.dz-complete .dz-progress {
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-in;
  -moz-transition: opacity 0.4s ease-in;
  -ms-transition: opacity 0.4s ease-in;
  -o-transition: opacity 0.4s ease-in;
  transition: opacity 0.4s ease-in;
}
.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  -webkit-animation: pulse 6s ease infinite;
  -moz-animation: pulse 6s ease infinite;
  -ms-animation: pulse 6s ease infinite;
  -o-animation: pulse 6s ease infinite;
  animation: pulse 6s ease infinite;
}
.dropzone .dz-preview .dz-progress {
  opacity: 1;
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  height: 16px;
  left: 50%;
  top: 50%;
  margin-top: -8px;
  width: 80px;
  margin-left: -40px;
  background: rgba(255, 255, 255, 0.9);
  -webkit-transform: scale(1);
  border-radius: 8px;
  overflow: hidden;
}
.dropzone .dz-preview .dz-progress .dz-upload {
  background: #333;
  background: linear-gradient(to bottom, #666, #444);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  -webkit-transition: width 300ms ease-in-out;
  -moz-transition: width 300ms ease-in-out;
  -ms-transition: width 300ms ease-in-out;
  -o-transition: width 300ms ease-in-out;
  transition: width 300ms ease-in-out;
}
.dropzone .dz-preview.dz-error .dz-error-message {
  display: block;
}
.dropzone .dz-preview.dz-error:hover .dz-error-message {
  opacity: 1;
  pointer-events: auto;
}
.dropzone .dz-preview .dz-error-message {
  pointer-events: none;
  z-index: 1000;
  position: absolute;
  display: block;
  display: none;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  border-radius: 8px;
  font-size: 13px;
  top: 130px;
  left: -10px;
  width: 140px;
  background: #be2626;
  background: linear-gradient(to bottom, #be2626, #a92222);
  padding: 0.5em 1.2em;
  color: white;
}
.dropzone .dz-preview .dz-error-message:after {
  content: "";
  position: absolute;
  top: -6px;
  left: 64px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #be2626;
}

.dz-preview 
    .dz-remove 
        .btns {
            background-color: red;
            color: #000000;
            border-radius: 50%;
            font-size: 10px;
            padding: 0;
            height: 24px;
            width: 24px;
            line-height: 22px;
            position: absolute;
            top: -7px;
            right: -5px;
            z-index: 999;
            opacity: 0;
            transition: 0.2s ease-in-out;
        }
    
    
    .dz-preview 
        .dz-remove 
            .btns:hover  {
                opacity: 1;
            }
        
.profile-complete{
    position: fixed;
    top: 0px;
    width: 30px;
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    z-index: 1000;
}

.header-home-logo{
    position: fixed;
    top:50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding-inline: 30px;
    width: 100%;
    height: 50px;
    background-color: #f8f8f8;
    z-index: 100;
    /* border-bottom: 1px solid #ccc; */
}


.profile-complete p {
    text-align: center;
    color: #fff;
    margin: 10px;
}
    


