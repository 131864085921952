@media (max-width: 1300px){
    .container{
        width: auto;
    }

    .app-selection-mobile{
    display: inline;
    }


    .header-menus{
        display: none;
    }

    .selector-container{
        width: auto;
        padding-left: 20px;
        margin-top: 10px;
    }

    .auth-title{
        font-size: large;
    }

    .app-selection-desktop{
        display: none;
    }

    .selector-container{
        width: auto;
        padding-left: 20px;
        padding-right: 25px;
    }

    .settings-component{
        display: flex;
        flex-direction: column;
        align-items: center;
        /* width: 100%; */
        margin-top: 80px;
    }

    .settings-component-inner-right{
         width: 850px;
    }

    .settings-component-inner-left{
         width: 850px;
    }

    .circular-bar{
        display: none;
    }

    .flex-lg{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .input-container{
        width: 100%;
        margin-bottom: 20px;
    }

    .file-uploader{
        width: 100%;
        height: 150px;
    }

    .account-types{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .account-type-select{
       width: 600px;
    }

    .single-selection{
        margin-top: 10px;
        color:#fff;
    }

    
    .bordered{
        border:1px solid #eee;
        padding: 10px;
    }

    .padding-right{
        padding-right: 10px;
    }

    .billing h3{
        text-align: center;
        font-weight: 400;
    }

    .notifications-container{
        padding-left: 10px;
    }

    .flex-items{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

}

@media (max-width: 767px){
.selector-container{
    /* padding-top: 20px; */
}

}



@media (max-width: 900px){
    .container{
        width: 100%;
    }

    .app-selection-mobile{
    display: inline;
    }

    .header-menus{
        display: none;
    }

    .selector-container{
        width: 600px;
        padding-left: 20px;
        margin-top: 10px;
    }

    .auth-title{
        font-size: large;
    }

    .app-selection-desktop{
        display: none;
    }

    .selector-container{
        padding-left: 20px;
        padding-right: 25px;
    }

    .settings-component{
        display: flex;
        flex-direction: column;
        align-items: center;
        /* width: 100%; */
    }

    .settings-component-inner-right{
         width: 600px;
    }

    .settings-component-inner-left{
         width: 600px;
    }

    .circular-bar{
        display: none;
    }

    .flex-lg{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .input-container{
        width: 100%;
        margin-bottom: 20px;
    }

    .file-uploader{
        width: 100%;
        height: 150px;
    }

    .account-types{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .account-type-select{
       width: 600px;
    }

    .single-selection{
        margin-top: 10px;
        color:#fff;
    }

    
    .bordered{
        border:1px solid #eee;
        padding: 10px;
    }

    .padding-right{
        padding-right: 10px;
    }

    .billing h3{
        text-align: center;
        font-weight: 400;
    }

    .notifications-container{
        padding-left: 10px;
    }

    .flex-items{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

}

@media (max-width: 760px){
    .container{
        width: 100%;
    }

    .app-selection-mobile{
    display: inline;
    }

    .selector-container{
        width: 500px;
        padding-left: 20px;
        margin-top: 10px;
    }

    .auth-title{
        font-size: small;
    }

    .app-selection-desktop{
        display: none;
    }

    .selector-container{
        padding-left: 20px;
        padding-right: 25px;
    }

    .settings-component{
        display: flex;
        flex-direction: column;
        align-items: center;
        /* width: 100%; */
    }

    .settings-component-inner-right{
         width: 500px;
    }

    .settings-component-inner-left{
         width: 500px;
    }

    .circular-bar{
        display: none;
    }

    .flex-lg{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .input-container{
        width: 100%;
        margin-bottom: 20px;
    }

    .file-uploader{
        width: 100%;
        height: 150px;
    }

    .account-types{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .account-type-select{
       width: 450px;
    }

    .single-selection{
        margin-top: 10px;
        color:#fff;
    }

    
    .bordered{
        border:1px solid #eee;
        padding: 10px;
    }

    .padding-right{
        padding-right: 10px;
    }

    .billing h3{
        text-align: center;
        font-weight: 400;
    }

    .notifications-container{
        padding-left: 10px;
    }

    .flex-items{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

}


@media (max-width: 560px){
    .container{
        width: 100%;
    }

    .app-selection-mobile{
    display: inline;
    }

    .selector-container{
        width: 430px;
        padding-left: 20px;
        margin-top: 10px;
    }

    .auth-title{
        font-size: small;
    }

    .app-selection-desktop{
        display: none;
    }

    .selector-container{
        padding-left: 20px;
        padding-right: 25px;
    }

    .settings-component{
        display: flex;
        flex-direction: column;
        align-items: center;
        /* width: 100%; */
    }

    .settings-component-inner-right{
         width: 430px;
    }

    .settings-component-inner-left{
         width: 430px;
    }

    .circular-bar{
        display: none;
    }

    .flex-lg{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .input-container{
        width: 100%;
        margin-bottom: 20px;
    }

    .file-uploader{
        width: 100%;
        height: 150px;
    }

    .account-types{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .account-type-select{
       width: 450px;
    }

    .single-selection{
        margin-top: 10px;
        color:#fff;
    }

    
    .bordered{
        border:1px solid #eee;
        padding: 10px;
    }

    .padding-right{
        padding-right: 10px;
    }

    .billing h3{
        text-align: center;
        font-weight: 400;
    }

    .notifications-container{
        padding-left: 10px;
    }

    .flex-items{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

@media (max-width: 450px){

    .inner-container{
    width: 300px !important;
    }

    .inner-container-register{
    width: 300px !important;
    height: auto;
    margin-left:-40px;
    margin-top: 50px;
    }
    .overlay-inner-container-otp{
    width: 320px !important;

    }

    .inner-container-register-otp{
    width: 300px !important;
    height: 280px;
    margin-left:-45px;
    }

    .overlay-inner-container{
    width: 300px !important;
    height: auto !important;

    }


    .container{
        width: 100%;
        background-color: red;
    }

    .app-selection-mobile{
    display: inline;
    }

    .selector-container{
        width: 360px;
        padding-left: 20px;
        /* padding-right: 20px; */
        margin-top: 10px;
    }

    .auth-title{
        font-size: small;
    }

    .app-selection-desktop{
        display: none;
    }

    .selector-container{
        padding-left: 20px;
        padding-right: 25px;
    }

    .settings-component{
        display: flex;
        flex-direction: column;
        align-items: center;
        /* margin-top: 200px; */
    }

    .settings-component-inner-right{
         width: 350px;
    }

    .settings-component-inner-left{
         width: 350px;
    }

    .account-type-select{
       width: 320px;
    }
    .circular-bar{
        display: none;
    }

    .flex-lg{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .input-container{
        width: 100%;
        margin-bottom: 20px;
    }

    .file-uploader{
        width: 100%;
        height: 150px;
    }

    .account-types{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .single-selection{
        margin-top: 10px;
        color:#fff;
    }

    
    .bordered{
        border:1px solid #eee;
    }

    .padding-right{
        padding-right: 10px;
    }

    .billing h3{
        text-align: center;
        font-weight: 400;
    }

    .notifications-container{
        padding-left: 10px;
    }

    .flex-items{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    

}

/* The Overlay (background) */
.overlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */   
  height: 100%;
  width: 0;
  position: fixed; /* Stay in place */
  z-index: 200; /* Sit on top */
  left: 0;
  top: 0;
  background-color: rgb(0,0,0); /* Black fallback color */
  background-color: #47878af6; /* Black w/opacity */
  overflow-x: hidden; /* Disable horizontal scroll */
  transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

/* Position the content inside the overlay */
.overlay-content {
  position: relative;
  top: 25%; /* 25% from the top */
  width: 100%; /* 100% width */
  text-align: center; /* Centered text/links */
  margin-top: 30px; /* 30px top margin to avoid conflict with the close button on smaller screens */
}

/* The navigation links inside the overlay */
.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #ffffff;
  display: block; /* Display block instead of inline */
  transition: 0.3s; /* Transition effects on hover (color) */
}

/* When you mouse over the navigation links, change their color */
.overlay a:hover, .overlay a:focus {
  color: #f1f1f1;
}

/* Position the close button (top right corner) */
.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}



/* When the height of the screen is less than 450 pixels, change the font-size of the links and position the close button again, so they don't overlap */
@media screen and (max-height: 450px) {
  .overlay a {font-size: 20px}
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
    color: #b75200;
  }

   .header-home-logo{
    top:50px
  }


}


